<template>
  <TransitionRoot
    :show="store.navProfileVisible"
    as="template"
  >
    <Dialog @close="dismiss">
      <DialogOverlay @click.self="dismiss" />
      <TransitionChild
        as="template"
        enter="duration-200 ease-out"
        enter-from="opacity-0 translate-x-full"
        enter-to="motion-reduce:transition-none translate-x-0"
        leave="duration-200 ease-in"
        leave-from="translate-x-0"
        leave-to="motion-reduce:transition-none translate-x-full"
      >
        <DialogPanel class="fixed top-0 right-0 bottom-0 w-3/4 max-w-sm bg-white">
          <CloseButton
            class="absolute left-0 top-0 w-16 h-16 text-black"
            aria-label="close navigation"
            @click="dismiss"
          />
          <nav class="nav-mobile">
            <Profile css="w-24 h-24" />
            <hr class="w-full">
            <div class="nav-links">
              <NavigationProfile @navigate="dismiss" />
            </div>
            <!-- <div class="flex items-center gap-2 mt-auto">
              <GetApp />
              <span>Get the App</span>
            </div> -->
          </nav>
        </DialogPanel>
      </TransitionChild>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel
} from '@headlessui/vue'
import { useAppStore } from '@/stores'

const store = useAppStore()
const dismiss = () => (store.navProfileVisible = false)
</script>
