<template>
  <div
    v-if="!nav.disabled"
    class="nav-link"
  >
    <div class="flex gap-3 items-center">
      <NuxtLink
        :to="nav.path"
        data-gtm-category="navigation"
        data-gtm-action="nav links - go to link"
        :data-gtm-label="nav.label"
        class="router-link group"
        :class="{ label: hasChildren }"
      >
        <div
          v-if="nav.icon"
          class="icon-wrapper p-0.5"
        >
          <SvgIcon
            :name="nav.icon"
            class="w-6 h-6"
          />
        </div>
        {{ nav.label }}
      </NuxtLink>
      <div
        v-if="nav.newTag"
        class="discount-tag mb-1"
      >
        25% Off
      </div>
    </div>
    <div
      v-if="nav.children"
      class="nav-children"
    >
      <NavigationLink
        v-for="child in nav.children"
        :key="child.path"
        :nav="child"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { NavigationLinkType } from '@/types'

export interface Props {
  nav: NavigationLinkType
}
const props = defineProps<Props>()
const hasChildren = props.nav.children && props.nav.children.length > 0
</script>
